import React from 'react';
import './Signout.scss';
import { useSelector, useDispatch } from 'react-redux';
import { signout } from '../../../slices/headerSlice';

const Signout = ({type}) => {
    const dispatch = useDispatch();

    const onSignout = () => {
        dispatch(signout());
    }
    return (
        <div>
            <span
                className={type === 'header' ? 'signout-icon-header' : 'signout-icon-menu'}
                title="Sign out"
                onClick={onSignout}
            ></span>
        </div>
    )
}

export default Signout