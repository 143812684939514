export const requestDetails = {
    'signin': '/gsf/amteamview/signin',
    'fetchCalenderDetails': '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/lor_absence_emp_facing_cal_view',
    'fetchSummary': '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/lor_absence_emp_facing_summary',
    'fetchSummaryTotal': '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/lor_absence_emp_facing_summary_total',
    'fetchTimeframeData': '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/get_absence_reporting_date',
    'fetchData': '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/lor_absence_team_view',
    'fetchDsGroupIds': '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/proxy/ProxyFetchCookieService',
    'fetchSecurityRefDsGroupIds': '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/get_dashboard_security_ref',
    'fetchInAppNotificationData': '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/get_lor_app_notification',
    'fetchCorporateStoreList': '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/get_absence_emp_facing_access',
    'fetchEmployeeDetails': '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/lor_absence_emp_facing_details',
    'fetchCorporateTimeframeData': '/gsf/amteamview/businessareas/retail/subjectareas/amteamview/services/get_absence_emp_facing_timeframe_corp',
    'signout': '/gsf/amteamview/signout',
}