import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import loadmask from '../component/loadmask/loadmask';
import * as request from '../service/Request';
import * as Util from "../util/Util";

const headers = {
   'Content-Type': 'application/json; charset=UTF-8',
    "Accept": "application/json",
    "Cache": "no-cache",
    "X-Requested-With": "XMLHttpRequest",
    "CSRF": window.csrf_token_val
};

const fetchApi = (requestType, method) => {
    const getRequestHeaderAndPayload = (requestType, payload) => {
        let requestPayload = {};

        switch(requestType) {
            case 'signin':
                let requestHeader = {...headers};

                requestHeader['Authorization'] = payload.authorizationCode ? payload.authorizationCode : "";

                requestPayload = {
                    requestHeader: requestHeader,
                    payloadConf: {}
                }
            break;
            case 'fetchCalenderDetails':
                let fetchCalenderDetailsPayload = {
                    filters: {
                        'StartDate': [payload.StartDate],
                        'EndDate': [payload.EndDate],
                        'EVENT_NAME': Util.getEvents(payload.Events, payload.Header),
                        'UI_VALUE': [payload.ExcusalStatus],
                        "CORP_ACCESS": [payload.CORP_ACCESS],
                        "MASKED_EMP_DS_ID": [payload.EMP_DS_ID]
                    }
                }

                requestPayload = {
                    requestHeader: headers,
                    payloadConf: fetchCalenderDetailsPayload
                }
            break;
            case 'fetchSummary':
                let fetchSummaryPayload = {
                    filters: {
                        'StartDate': [payload.StartDate],
                        'EndDate': [payload.EndDate],
                        'EVENT_NAME': Util.getEvents(payload.Events, payload.Header),
                        'UI_VALUE': [payload.ExcusalStatus],
                        "CORP_ACCESS": [payload.CORP_ACCESS],
                        "MASKED_EMP_DS_ID": [payload.EMP_DS_ID]
                    }
                }

                requestPayload = {
                    requestHeader: headers,
                    payloadConf: fetchSummaryPayload
                }
            break;
            case 'fetchSummaryTotal':
                let fetchSummaryTotalPayload = {
                    filters: {
                        'StartDate': [payload.StartDate],
                        'EndDate': [payload.EndDate],
                        'EVENT_NAME': Util.getEvents(payload.Events, payload.Header),
                        'UI_VALUE': [payload.ExcusalStatus],
                        "CORP_ACCESS": [payload.CORP_ACCESS],
                        "MASKED_EMP_DS_ID": [payload.EMP_DS_ID]
                    }
                }

                requestPayload = {
                    requestHeader: headers,
                    payloadConf: fetchSummaryTotalPayload
                }
            break;
            case 'fetchTimeframeData':
                requestPayload = {
                    requestHeader: headers,
                    payloadConf: {}
                }
            break;
            case 'fetchData':
                let fetchDataPayload = {
                    filters: {
                        StartDate: [payload.StartDate],
                        EndDate: [payload.EndDate],
                        ABSENCE_TYPE_CD: [payload.AbsenceType],
                    }
                }

                requestPayload = {
                    requestHeader: headers,
                    payloadConf: fetchDataPayload
                }
            break;
            case 'fetchDsGroupIds':
                requestPayload = {
                    requestHeader: headers,
                    payloadConf: {}
                }
            break;
            case 'fetchSecurityRefDsGroupIds':
                requestPayload = {
                    requestHeader: headers,
                    payloadConf: {}
                }
            break;
            case 'fetchInAppNotificationData':
                let fetchInAppNotificationDataPayload = {
                    filters: {
                       'Screen_Name': ["Personal Absence Report"]
                    }
                }

                requestPayload = {
                    requestHeader: headers,
                    payloadConf: fetchInAppNotificationDataPayload
                }
            break;
            case 'fetchCorporateStoreList':
                let fetchCorporateStoreListPayload = {
                    filters: {
                        Ds_Grp_Id: payload.cookieData.dsGroupIds.split(',').map(String)
                    }
                }

                requestPayload = {
                    requestHeader: headers,
                    payloadConf: fetchCorporateStoreListPayload
                }
            break;
            case 'fetchEmployeeDetails':
                let fetchEmployeeDetailsPayload = {
                    filters: {
                        StartDate: [payload.StartDate],
                        EndDate: [payload.EndDate],
                        STORE_CD: [payload.STORE_CD.Store_Cd]
                    }
                }

                requestPayload = {
                    requestHeader: headers,
                    payloadConf: fetchEmployeeDetailsPayload
                }
            break;
            case 'fetchCorporateTimeframeData':
                requestPayload = {
                    requestHeader: headers,
                    payloadConf: {}
                }
            break;
            case 'signout':
                requestPayload = {
                    requestHeader: headers,
                    payloadConf: {}
                }
            break;
            default:
            break;
        }
        return requestPayload;
    };
    return createAsyncThunk(`data/${requestType}`, async (payload) => {
        //console.log("-1-1-1", payload);

        loadmask.show();

        try {
            let requestHeaderAndPayload = getRequestHeaderAndPayload(requestType, payload);

            //console.log(requestHeaderAndPayload)

            const result = await axios({
                url: request.requestDetails[requestType],
                headers: requestHeaderAndPayload.requestHeader,
                data: requestHeaderAndPayload.payloadConf,
                method: method,
            });

            loadmask.hide();

            return result.data;
        } catch (error) {
            loadmask.hide();
            throw error;
        }
    });
};

export default fetchApi;