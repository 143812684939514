import React, {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';
import RootRouter from './routes/RootRouter';
import './App.css';
import loadmask from './component/loadmask/loadmask';
import * as Util from "./util/Util"

function App({reporter}) {
  let isBrowserCompatible = true;
    let {browserName, version} = Util.getBrowserInfo();
    
    if(browserName && browserName === 'Safari') {
        if(version < 17) {
            isBrowserCompatible = false;
        }
    } else if(browserName && browserName === 'Google Chrome') {
      if(version < 131) {
          isBrowserCompatible = false;
      }
    } else if(browserName && browserName === 'Mozilla Firefox') {
      if(version < 133) {
          isBrowserCompatible = false;
      }
    } 

console.log("-a-a-a-a-a-assss", browserName, version)

    if(!isBrowserCompatible) {
        alert(`You are using ${browserName} v${version}. This version may not compatible with the dashboard. Please upgrade to the latest version available`);
    } 
    
    return (
      <BrowserRouter>
        <RootRouter reporter={reporter}/>
      </BrowserRouter>
    )
};

export default App;