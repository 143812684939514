import React from 'react';
import './SignoutContainer.scss';

const SignoutContainer = () => {
  return (
    <div className='signout-container'>
        <div className='signout-logo-container'>
            <div className='signout-apple-logo-div'>
                <span className='signout-apple-logo-span'></span>
            </div>
            <div className='signout-content-div'>
                <div className='signout-msg'>You have been signed out</div>
                <div>
                    <a href="https://retailpersonalabsence.apple.com">Continue</a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default SignoutContainer