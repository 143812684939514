import React from 'react';
import { useSelector } from 'react-redux';
import SummaryCointainer from '../component/summary/SummaryContainer';
import Calender from './calender/Calender';
import MobileCalender from './calender/MobileCalender';

const AbsenceCointainer = () => {

    const isMobile = window.matchMedia("(max-width: 768px)").matches;
    const header = useSelector((state) => state.header);
    //console.log(header.isMobile,"MOB")
    return (
        <div id="container" className={`${header.corporateEmployee ? 'corporate' : 'non-corporate'} ${(header.isMobile == 'Y') ? 'ipad' : 'desktop'}`}>
            <SummaryCointainer/>
            {isMobile ? (<MobileCalender />) : (<Calender />)}
        </div>
    )
}

export default AbsenceCointainer;